<template>
  <div class="details">
    <particulars></particulars>
  </div>
</template>

<script>
import particulars from "@/layout/particulars.vue";
export default {
  components: {
    particulars,
  },
};
</script>

<style lang="scss" scoped>
.details {
  max-width: 1200px;
   min-width: 1150px;
  margin:  20px auto;
}
</style>